import React, {useState} from 'react';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import {Auth} from "aws-amplify";
import {useAppContext} from "../common/context";
import LoaderButton from "../component/LoaderButton";
import {useFormFieldsId} from "../common/hooks";
import {useLocation, useNavigate} from "react-router-dom";
import UnauthenticatedPage from "../component/UnauthenticatedPage";
import {Trans, useTranslation} from "react-i18next";
import {InputAdornment} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Edit, Visibility, VisibilityOff} from "@mui/icons-material";
import {OpenApi} from "../api/openApi";
import {validateEmail} from "../common/util";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function Login() {
    const {userHasAuthenticated, setToast} = useAppContext();
    const location = useLocation();
    const [fields, handleFieldChange] = useFormFieldsId({
        email: location.state?.email ? location.state.email : "",
        password: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const [defaultLogin, setDefaultLogin] = useState(false);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    async function handleEmailSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            let idp = await OpenApi.getIdentityProvider(fields.email);
            if (idp.name !== "default") {
                await Auth.federatedSignIn({provider: idp.name});
            } else {
                setDefaultLogin(true);
                setIsLoading(false);
            }
        } catch (e) {
            setToast({message: t('login.error'), error: e, severity: "error"});
            setIsLoading(false);
        }
    }

    async function handleLogin(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            await Auth.signIn(fields.email, fields.password);
            userHasAuthenticated(true);
        } catch (e) {
            if (e.code === "UserNotConfirmedException") {
                navigate("/confirm", {state: {email: fields.email, password: fields.password}});
                return;
            }
            setToast({message: t('login.error'), error: e, severity: "error"});
        }
        setIsLoading(false);
    }

    function renderDefaultLogin() {
        return (
            <form onSubmit={handleLogin}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="email"
                    label={t('general.email')}
                    type='text'
                    InputProps={{
                        endAdornment:
                            (<InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setDefaultLogin(false)}
                                    edge="end"
                                >
                                    <Edit/>
                                </IconButton>
                            </InputAdornment>)
                    }}
                    disabled={true}
                    value={fields.email}
                    id="email"
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={t('general.password')}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment:
                            (<InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>)
                    }}
                    value={fields.password}
                    id="password"
                    autoComplete="off"
                    onChange={handleFieldChange}
                />
                <Box display="flex" mb={2}
                     justifyContent="right">
                    <Link
                        color={"textSecondary"}
                        variant="body2"
                        underline="hover"
                        style={{cursor: "pointer"}}
                        onClick={() => navigate("/login/reset", {state: {email: fields.email}})}>
                        {t('login.forgot')}
                    </Link>
                </Box>
                <LoaderButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    isLoading={isLoading}
                    disabled={fields.password.length === 0}
                >
                    {t('login.submit')}
                </LoaderButton>
                <Typography mt={1} color={"textPrimary"} variant={"body2"}>
                    <Trans i18nKey={'login.signUp'}>
                        Don't have an account?
                        <Link
                            color={"primary"}
                            variant="body2"
                            underline="hover"
                            style={{cursor: "pointer"}}
                            onClick={() => navigate("/signup", {state: {email: fields.email}})}>
                            Sign up
                        </Link>
                    </Trans>
                </Typography>
            </form>
        );
    }

    function renderEmailCheck() {
        return (

            <form onSubmit={handleEmailSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={t('general.email')}
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={fields.email}
                    onChange={handleFieldChange}
                    sx={{mb: 4}}
                />
                <LoaderButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    isLoading={isLoading}
                    disabled={!validateEmail(fields.email)}
                >
                    {t('login.next')}
                </LoaderButton>
            </form>
        );
    }

    function render() {
        if (defaultLogin) {
            return renderDefaultLogin();
        } else {
            return renderEmailCheck();
        }
    }

    return (
        <UnauthenticatedPage>
            <Typography color={"textPrimary"} variant={"subtitle1"}>{t("login.title")}</Typography>
            <Box width={"100%"}>
                {render()}
            </Box>
        </UnauthenticatedPage>
    );
}
