import UnauthenticatedPage from "../component/UnauthenticatedPage";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import {useSearchParams} from "react-router-dom";
import deviceAuthApi from "../api/deviceAuthApi";
import {useTranslation} from "react-i18next";

export default function DeviceAuthCallback() {
    const [searchParams] = useSearchParams();
    const error = searchParams.get('auth_error');
    const clientId = searchParams.get('client_id');
    const {t} = useTranslation();

    function render() {
        if (error) {
            return (
                <Box display={"flex"} flexDirection={"column"}>
                    <Typography align={"center"} variant={"subtitle1"}
                                color={"textPrimary"}>{t("deviceAuth.callback.error")}</Typography>
                    {error &&
                        <Typography align={"center"} variant={"body2"}
                                    color={"textSecondary"}>{error}</Typography>
                    }
                    <Typography align={"center"} variant={"body2"}
                                color={"textSecondary"}>{t("deviceAuth.callback.retry")}</Typography>
                </Box>
            )
        }

        return (
            <Box display={"flex"} flexDirection={"column"}>
                <Typography align={"center"} variant={"subtitle1"}
                            color={"textPrimary"}>{t("deviceAuth.callback.success")}</Typography>
                {clientId &&
                    <Typography align={"center"} color={"textSecondary"}
                                variant={"body2"}>{t("deviceAuth.callback.next", {"client": deviceAuthApi.getClientName(clientId)})}</Typography>
                }
            </Box>
        );
    }

    return (
        <UnauthenticatedPage>
            {render()}
        </UnauthenticatedPage>
    )
}