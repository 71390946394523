import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useFormFieldsName } from '../common/hooks';
import LoaderButton from './LoaderButton';
import { HelpText } from './HelpText';
import { ConfirmedAction } from './ConfirmedAction';

import { Delete } from '@mui/icons-material';

import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

import { EditableLabel } from './EditableLabel';
import { useAppContext } from '../common/context';
import { Roles } from '../common/util';

import bcfApi from '../api/bcfApi';
import bcfExtensionsApi from '../api/bcfExtensionsApi';

export default function VrexBcfSettings({ project }) {
    const { t } = useTranslation();
    const { setToast } = useAppContext();
    const [labels, setLabels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isEditingLabel, setIsEditingLabel] = useState(false);
    const theme = useTheme();

    const loadLabels = useCallback(async () => {
        setLoading(true);
        try {
            setLabels(await bcfExtensionsApi.getLabels(project.id));
        } catch (e) {
            setToast({
                message: t('project.settings.bcf.labels.error'),
                error: e,
                severity: 'error',
            });
        }
        setLoading(false);
    }, [project, setToast, t]);

    useEffect(() => {
        loadLabels();
    }, [setLoading, loadLabels]);

    const removeLabelEntry = (label) => {
        setLabels(labels.filter((l) => l.id !== label.id));
    };

    const addLabelEntry = (label) => {
        setLabels([...labels, label]);
    };

    const onEditStart = () => {
        setIsEditingLabel(true);
    }

    const onEditEnd = () => {
        setIsEditingLabel(false);
    };

    return (
        <Box>
            <Typography variant={'h6'} color={'textPrimary'}>
                {t('project.settings.bcf.labels.title')}
                <HelpText text={t('project.settings.bcf.labels.description')} />
            </Typography>
            <Box>
                {labels.map((l) => (
                    <IssueLabel
                        key={l.id}
                        label={l}
                        project={project}
                        onEditStart={onEditStart}
                        onEditEnd={onEditEnd}
                        onDeleted={removeLabelEntry}
                    />
                ))}
            </Box>
            <CreateLabelDialog
                project={project}
                onCreated={addLabelEntry}
                isLoading={loading}
                disabled={isEditingLabel || loading}
                style={{ marginTop: theme.spacing(1) }}
            ></CreateLabelDialog>
        </Box>
    );
}

function IssueLabel({ label, project, onUpdated, onDeleted, onEditStart, onEditEnd }) {
    const { setToast } = useAppContext();
    const { t } = useTranslation();
    const [isEditing, setIsEditing] = useState(false);
    const [topics, setTopics] = useState([]);
    const maxTopicCount = 20;

    const _onEditStart = () => {
        setIsEditing(true);
        onEditStart();
    }

    const _onEditEnd = () => {
        setIsEditing(false);
        onEditEnd();
    };

    const loadTopics = async (labelName) => {
        try {
            const query = `$top=${maxTopicCount + 1}&$filter=contains(labels, '${labelName}')`;
            const topics = await bcfApi.getTopics(project.id, query);
            setTopics(topics);
        } catch (e) {
            setToast({
                message: t('project.settings.bcf.topics.error'),
                error: e,
                severity: 'error',
            });
        }
    };

    const updateLabel = async (labelName) => {
        try {
            await bcfExtensionsApi.updateLabel(project.id, label.id, labelName);
            label.label = labelName;
            onUpdated?.(label);
            return true;
        } catch (e) {
            setToast({
                message: t('project.settings.bcf.labels.update.error'),
                error: e,
                severity: 'error',
            });
            return false;
        }
    };

    const deleteLabel = async () => {
        try {
            await bcfExtensionsApi.deleteLabel(project.id, label.id);
            onDeleted?.(label);
        } catch (e) {
            setToast({
                message: t('project.settings.bcf.labels.delete.error'),
                error: e,
                severity: 'error',
            });
        }
    };

    return (
        <Box key={label.id} display={'flex'} alignItems={'center'}>
            <EditableLabel
                display="inline-flex"
                initialText={label.label}
                onValueChanged={(name) => updateLabel(name)}
                onEditStart={_onEditStart}
                onEditEnd={_onEditEnd}
            />
            {!isEditing && (
                <ConfirmedAction
                    title={t('project.settings.bcf.labels.delete.title')}
                    description={t('project.settings.bcf.labels.delete.description', {
                        name: label.label,
                        count: topics.length > maxTopicCount ? `>${maxTopicCount}` : topics.length,
                    })}
                    onConfirmed={deleteLabel}
                    onOpen={() => loadTopics(label.label)}
                >
                    <IconButton size={'small'}>
                        <Delete sx={{ fontSize: 16 }} />
                    </IconButton>
                </ConfirmedAction>
            )}
        </Box>
    );
}

function CreateLabelDialog({ project, onCreated, disabled, display, style }) {
    const theme = useTheme();
    const { setToast } = useAppContext();
    const [loading, setLoading] = useState(false);

    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const [fields, setFields] = useFormFieldsName({
        name: '',
        organizationIndex: 0,
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setFields({
            target: {
                name: 'name',
                value: '',
            },
        });
        setOpen(false);
    };

    const validateForm = () => fields.name.length > 0;

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            createLabel();
        }
    };

    const createLabel = async () => {
        setLoading(true);
        try {
            const label = await bcfExtensionsApi.createLabel(project.id, fields.name);
            onCreated(label);
            closeDialog();
        } catch (e) {
            setToast({
                message: t('project.settings.bcf.labels.add.error'),
                error: e,
                severity: 'error',
            });
        }
        setLoading(false);
    };

    return (
        <Box display={display || 'block'} style={style} flexDirection={'row-reverse'}>
            <Button
                variant={'contained'}
                color="primary"
                disabled={disabled || project.role.accessLevel <= Roles.COORDINATOR.accessLevel}
                onClick={handleClickOpen}
            >
                {t('project.settings.bcf.labels.add.action')}
            </Button>

            <Dialog
                open={open}
                onClose={closeDialog}
                aria-labelledby="form-dialog-title"
                closeAfterTransition={false}
            >
                <DialogTitle id="form-dialog-title">
                    {t('project.settings.bcf.labels.add.title')}
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <Box display={'flex'} flexDirection={'column'} marginTop={2}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                label="Label"
                                name="name"
                                autoFocus
                                onChange={setFields}
                            />
                        </Box>
                    </form>
                </DialogContent>
                <DialogActions
                    style={{ flexDirection: 'row-reverse', justifyContent: 'flex-start' }}
                    disableSpacing
                >
                    <LoaderButton
                        variant="contained"
                        color="primary"
                        isLoading={loading}
                        disabled={!validateForm()}
                        onClick={createLabel}
                    >
                        {t('general.create')}
                    </LoaderButton>
                    <Button
                        color={'secondary'}
                        variant={'outlined'}
                        onClick={closeDialog}
                        style={{ marginRight: theme.spacing(1) }}
                    >
                        {t('general.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
