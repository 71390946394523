import {get} from "./vrexAuth";

const pageSize = 100;

class BimsyncApi {
    static async getProjects() {
        let page = 1;
        let response = await get(`${process.env.REACT_APP_INTEGRATION_PROXY_URL}/bimsync/v2/projects?pageSize=${pageSize}&page=${page}`);
        let projects = await response.json();
        while (hasNext(response)) {
            page += 1;
            response = await get(`${process.env.REACT_APP_INTEGRATION_PROXY_URL}/bimsync/v2/projects?pageSize=${pageSize}&page=${page}`);
            projects = projects.concat(await response.json());
        }
        return projects;
    }

    static async getModels(projectId) {
        let page = 1;
        let response = await get(`${process.env.REACT_APP_INTEGRATION_PROXY_URL}/bimsync/v2/projects/${projectId}/models?pageSize=${pageSize}&page=${page}`);
        let models = await response.json();
        while (hasNext(response)) {
            page += 1;
            response = await get(`${process.env.REACT_APP_INTEGRATION_PROXY_URL}/bimsync/v2/projects/${projectId}/models?pageSize=${pageSize}&page=${page}`);
            models = models.concat(await response.json());
        }
        return models;
    }
}

function hasNext(response) {
    let link = response.headers.get("Link");
    if (!link) {
        return false
    }
    return link.includes("rel=\"next\"");
}

export default BimsyncApi;
