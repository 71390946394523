import React, {useEffect, useState, useRef} from 'react';
import {Box, IconButton, TextField, Typography} from "@mui/material";
import {CloseOutlined, DoneOutlined, EditOutlined} from "@mui/icons-material";

export function EditableLabel({ initialText, onValueChanged, onEditStart, onEditEnd, disabled, variant, display }) {
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [text, setText] = useState(initialText);
    const inputRef = useRef(null);

    useEffect(() => {
        setText(initialText)
    }, [initialText]);

    useEffect(() => {
        if (isEditing) {
            inputRef.current?.focus();
        }
    }, [isEditing]);

    function beginEdit(event) {
        event.stopPropagation();
        setIsEditing(true);

        if (onEditStart)
            onEditStart();
    }

    function endEdit() {
        setIsEditing(false);

        if (onEditEnd)
            onEditEnd();
    }

    function cancel() {
        setText(initialText);
        endEdit();
    }


    async function save() {
        if (initialText === text) {
            endEdit();
            return;
        }

        setIsSaving(true);
        const success = await onValueChanged(text);
        setIsSaving(false);

        if (success === false) {
            setText(initialText);
        }
        else
            endEdit();
    }

    function handleKeyDown(event) {
        if (event.key === 'Escape') {
            cancel(event);
        } else if (event.key === 'Enter') {
            save(event);
        }
    }

    function preventBlur(event) {
        event.preventDefault();
    }

    return (
        <Box pl={2} sx={{ display: display || 'block' }}>
            {disabled ? (
                <Box display={'flex'} alignItems={'center'}>
                    <Box clone pr={1}>
                        <Typography>{text}</Typography>
                    </Box>
                </Box>
            ) : isEditing ? (
                <Box display={'flex'} alignItems={'center'}>
                    <TextField
                        inputRef={inputRef}
                        variant={'standard'}
                        defaultValue={initialText}
                        onClick={(event) => event.stopPropagation()}
                        onChange={(event) => setText(event.target.value)}
                        onKeyDown={handleKeyDown}
                        onBlur={cancel}
                    />
                    <IconButton
                        size={'small'}
                        onClick={save}
                        onMouseDown={preventBlur}
                    >
                        <DoneOutlined sx={{ fontSize: 16 }} />
                    </IconButton>
                    <IconButton
                        size={'small'}
                        onClick={cancel}
                        onMouseDown={preventBlur}
                    >
                        <CloseOutlined sx={{ fontSize: 16 }} />
                    </IconButton>
                </Box>
            ) : (
                <Box display={'flex'} alignItems={'center'}>
                    <Box clone pr={1}>
                        <Typography variant={variant}>{text}</Typography>
                    </Box>
                    <IconButton size={'small'} onClick={beginEdit} disabled={isSaving}>
                        <EditOutlined sx={{ fontSize: 16 }} />
                    </IconButton>
                </Box>
            )}
        </Box>
    );
}
