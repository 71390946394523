import UnauthenticatedPage from "../component/UnauthenticatedPage";
import React from 'react';
import {Link as RouterLink, Navigate, useSearchParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import {useTranslation} from "react-i18next";

export default function LoginCallback() {
    const [searchParams] = useSearchParams();
    const {t} = useTranslation();
    let errorDescription = searchParams.get("error_description");
    if (errorDescription) {
        return (
            <UnauthenticatedPage>
                <Typography variant={"subtitle1"}>{t("login.error")}</Typography>
                <Typography variant={"body2"} color={"textSecondary"}>{errorDescription}</Typography>
                <Link
                    mt={1}
                    color={"textSecondary"}
                    component={RouterLink}
                    to={"/login"}
                    variant="body2"
                    underline="hover">
                    {t('login.retry')}
                </Link>
            </UnauthenticatedPage>
        )
    } else {
        return (<Navigate to={"/login"} replace={true}></Navigate>)
    }
}