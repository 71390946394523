import React, {useCallback, useEffect, useState} from "react";
import MainPage from "../component/MainPage";
import Typography from "@mui/material/Typography";
import {useAppContext} from "../common/context";
import Grid from "@mui/material/Grid";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import deviceAuthApi from "../api/deviceAuthApi";
import IconButton from "@mui/material/IconButton";
import {Delete} from "@mui/icons-material";
import {ConfirmedAction} from "../component/ConfirmedAction";

export default function Devices() {
    const {isAuthenticated, setToast} = useAppContext();
    const [authorizations, setAuthorizations] = useState([]);
    const [loading, setLoading] = useState(true);
    const theme = useTheme();
    const {t} = useTranslation();

    const loadAuthorizations = useCallback(async () => {
        async function fetchAuthorizations() {
            try {
                return await deviceAuthApi.getAuthorizations();
            } catch (e) {
                setToast({message: t('devices.error'), error: e, severity: "error"})
                return [];
            }
        }

        const a = await fetchAuthorizations();
        setAuthorizations(a);
        setLoading(false);
    }, [setToast, t]);

    useEffect(() => {
        if (isAuthenticated) {
            loadAuthorizations().then();
        }
    }, [isAuthenticated, loadAuthorizations]);

    async function onDeleteAuthorization(id) {
        try {
            await deviceAuthApi.deleteAuthorization(id);
            let auths = authorizations.filter((auth) => {
                return auth.id !== id
            });
            setAuthorizations(auths);
        } catch (e) {
            setToast({message: t('devices.delete.error'), error: e, severity: "error"})
        }
    }

    function render() {
        if (loading) {
            return <></>
        }
        if (authorizations.length === 0) {
            return (
                <Typography>
                    {t("devices.none")}
                </Typography>
            )
        } else {
            return (
                <>
                    <Typography mb={2} variant="body2" color={"textSecondary"}>{t("devices.description")}</Typography>
                    {authorizations.map((authorization, i) => (
                        <Box key={i} clone bgcolor={"background.paper"} mb={2} p={2} boxShadow={1}
                             color={"text.secondary"}
                             display={"flex"} alignItems={"center"} flexDirection={"row"}>
                            <Box flexGrow={1}>
                                <Typography variant={"subtitle1"}
                                            color={"textPrimary"}>{deviceAuthApi.getClientName(authorization.clientId)}</Typography>
                                <Typography variant={"body2"}
                                            color={"textSecondary"}>{authorization.device.name + ", " + authorization.device.location}</Typography>
                                <Typography variant={"body2"}
                                            color={"textSecondary"}>{"Expires: " + new Date(authorization.expiry).toLocaleDateString()}</Typography>
                            </Box>
                            <ConfirmedAction title={t("devices.delete.dialog.title")}
                                             description={t("devices.delete.dialog.description")}
                                             onConfirmed={() => onDeleteAuthorization(authorization.id)}>
                                <IconButton size={"large"}><Delete/></IconButton>
                            </ConfirmedAction>
                        </Box>
                    ))
                    }
                </>
            )
        }
    }

    return (
        <MainPage title="Devices">
            <Grid container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  sx={{flexGrow: '1'}}>
                <Grid item xs={12} sm={10} md={7} lg={7} xl={5}
                      style={{
                          paddingLeft: "6vw",
                          paddingRight: "6vw",
                          paddingTop: theme.spacing(4),
                          backgroundColor: theme.palette.background.list
                      }}>
                    {render()}
                </Grid>
            </Grid>
        </MainPage>
    )
}
