import React, {useState} from 'react';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import Typography from '@mui/material/Typography';
import {Auth} from "aws-amplify";
import LoaderButton from "../component/LoaderButton";
import {useAppContext} from "../common/context";
import UnauthenticatedPage from "../component/UnauthenticatedPage";
import {Trans, useTranslation} from "react-i18next";
import {Checkbox, InputAdornment} from "@mui/material";
import Box from "@mui/material/Box";
import {requiredField, validatePasswordField} from "../common/util";
import IconButton from "@mui/material/IconButton";
import {Edit, Visibility, VisibilityOff} from "@mui/icons-material";
import {OpenApi} from "../api/openApi";

export default function SignUp() {
    const [isLoading, setIsLoading] = useState(false)
    const {setToast} = useAppContext();
    const location = useLocation();
    const [fields, setFields] = useState({
        firstName: {value: "", visited: false, valid: false, errors: []},
        lastName: {value: "", visited: false, valid: false, errors: []},
        email: {value: location.state?.email, visited: true, valid: true, errors: []},
        password: {value: "", visited: false, valid: false, errors: []},
        terms: {value: false},
    });
    const [valid, setValid] = useState(false);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    if (!fields.email.value) {
        return <Navigate to={"/login"}/>
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            let idp = await OpenApi.getIdentityProvider(fields.email.value);
            if (idp.name !== "default") {
                setToast({message: t('signup.error-sso'), severity: "error"});
                return;
            }
            await Auth.signUp({
                username: fields.email.value,
                password: fields.password.value,
                attributes: {
                    email: fields.email.value,
                    given_name: fields.firstName.value,
                    family_name: fields.lastName.value,
                }
            });
            setIsLoading(false);
            navigate("/confirm", {state: {email: fields.email.value, password: fields.password.value}});
        } catch (e) {
            setToast({message: t('signup.error'), error: e, severity: "error"});
            setIsLoading(false);
        }
    }

    function handleFieldChange(event) {
        fields[event.target.name].value = event.target.value;
        validateFields();
        setFields({
            ...fields,
        });
    }

    function handleBlur(event) {
        if (fields[event.target.name].visited) {
            return;
        }
        fields[event.target.name].visited = true;
        validateFields();
        setFields({
            ...fields,
        });
    }

    function validateFields() {
        requiredField(fields.firstName, [t('general.validation.firstName.required')]);
        requiredField(fields.lastName, [t('general.validation.lastName.required')]);
        validatePasswordField(fields.password);
        setValid(fields.email.valid && fields.password.valid && fields.firstName.valid && fields.lastName.valid && fields.terms.value);
    }

    return (
        <UnauthenticatedPage>
            <Typography color={"textPrimary"} variant={"subtitle1"}>{t("signup.title")}</Typography>
            <form noValidate onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="email"
                    label={t('general.email')}
                    type='text'
                    InputProps={{
                        endAdornment:
                            (<InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => navigate("/login", {state: {email: fields.email.value}})}
                                    edge="end"
                                >
                                    <Edit/>
                                </IconButton>
                            </InputAdornment>)
                    }}
                    disabled={true}
                    value={fields.email.value}
                    id="email"
                />
                <Box my={2} display={"flex"} gap={2}>
                    <TextField
                        autoComplete="given-name"
                        name="firstName"
                        variant="outlined"
                        required
                        fullWidth
                        label={t('signup.firstname')}
                        value={fields.firstName.value}
                        error={fields.firstName.errors.length > 0}
                        helperText={fields.firstName.errors}
                        autoFocus
                        onChange={handleFieldChange}
                        onBlur={handleBlur}
                    />
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        label={t('signup.lastname')}
                        name="lastName"
                        autoComplete="family-name"
                        value={fields.lastName.value}
                        error={fields.lastName.errors.length > 0}
                        helperText={fields.lastName.errors}
                        onChange={handleFieldChange}
                        onBlur={handleBlur}
                    />
                </Box>
                <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label={t('general.password')}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment:
                            (<InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>)
                    }}
                    autoComplete="off"
                    value={fields.password.value}
                    error={fields.password.errors.length > 0}
                    helperText={fields.password.errors}
                    onChange={handleFieldChange}
                    onBlur={handleBlur}
                />
                <Box my={1} display={"flex"} alignItems={"center"}>
                    <Checkbox
                        color={"primary"}
                        required
                        checked={fields.terms.value}
                        onChange={(event) => handleFieldChange({
                            target: {
                                name: "terms",
                                value: event.target.checked
                            }
                        })}
                    />
                    <Typography>
                        <Trans i18nKey="signup.terms">
                            I agree to the <Link
                            href="https://www.vrex.no/vrex-eula/"
                            target={"_blank"}
                            underline="hover">Terms</Link>
                        </Trans>
                    </Typography>
                </Box>
                <LoaderButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    isLoading={isLoading}
                    disabled={!valid}
                >
                    {t('signup.submit')}
                </LoaderButton>
                <Typography mt={1} color={"textPrimary"} variant={"body2"}>
                    <Trans i18nKey={'signup.haveAccount'}>
                        Don't have an account?
                        <Link
                            color={"primary"}
                            variant="body2"
                            underline="hover"
                            style={{cursor: "pointer"}}
                            onClick={() => navigate("/login", {state: {email: fields.email.value}})}>
                            Sign up
                        </Link>
                    </Trans>
                </Typography>
            </form>
        </UnauthenticatedPage>
    );
}