import {del, get, patch, post} from "./vrexAuth";

class VrexApi {

    static async getUser() {
        let response = await get(process.env.REACT_APP_API_URL + "/users/me");
        return await response.json();
    }

    static async getProjects(showAll) {
        let filter = showAll ? "?includeNonMemberProjects=true&includeInactiveProjects=true" : ""
        let response = await get(process.env.REACT_APP_API_URL + "/projects" + filter);
        return await response.json();
    }

    static async getOrganizationProjects(organizationId) {
        let response = await get(process.env.REACT_APP_API_URL + "/organizations/" + organizationId + "/projects");
        return await response.json();
    }

    static async getOrganizations() {
        let response = await get(process.env.REACT_APP_API_URL + "/organizations");
        return await response.json();
    }

    static async getProject(projectId) {
        let response = await get(process.env.REACT_APP_API_URL + "/projects/" + projectId);
        return await response.json();
    }

    static async postProject(organizationId, project) {
        let response = await post(process.env.REACT_APP_API_URL + "/projects", project);
        return await response.json();
    }

    static async patchProject(projectId, project) {
        let response = await patch(process.env.REACT_APP_API_URL + "/projects/" + projectId, project);
        return await response.json();
    }

    static async deleteProject(projectId) {
        await del(process.env.REACT_APP_API_URL + "/projects/" + projectId);
    }

    static async deleteProjectActivation(projectId) {
        await del(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/activation");
    }

    static async postProjectActivation(projectId) {
        await post(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/activation", null);
    }

    static async postProjectUser(projectId, user) {
        let response = await post(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/users", user);
        return await response.json();
    }

    static async postProjectInvitation(projectId, invitation) {
        let response = await post(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/users/invitations", invitation);
        return await response.json();
    }

    static async getProjectUsers(projectId) {
        let response = await get(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/users");
        return await response.json();
    }

    static async postProjectRoom(projectId, room) {
        let response = await post(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/rooms", room);
        return await response.json();
    }

    static async deleteProjectRoom(projectId, roomId) {
        await del(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/rooms/" + roomId);
    }

    static async getProjectRooms(projectId) {
        let response = await get(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/rooms");
        return await response.json();
    }

    static async getModelSources(projectId) {
        let response = await get(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/contents/model-sources");
        return await response.json();
    }

    static async postUpload(projectId, modelSourceId, upload) {
        let response = await post(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/contents/local/" + modelSourceId + "/uploads", upload);
        return await response.json();
    }

    static async getIntegration(projectId, integrationId, includeModels = true) {
        let response = await get(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/contents/integrations/" + integrationId + "?includeModels=" + includeModels);
        return await response.json();
    }

    static async postIntegration(projectId, integration) {
        let response = await post(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/contents/integrations", integration);
        return await response.json();
    }

    static async postIntegrationModel(projectId, integrationId, model) {
        let response = await post(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/contents/integrations/" + integrationId + "/models", model);
        return await response.json();
    }

    static async postProcessRequest(projectId, integrationId) {
        let response = await post(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/contents/integrations/" + integrationId + "/process-requests", null);
        return await response.json();
    }

    static async deleteModelSource(projectId, modelSourceId) {
        await del(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/contents/model-sources/" + modelSourceId);
    }

    static async patchModelSource(projectId, modelSourceId, modelSource) {
        let response = await patch(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/contents/model-sources/" + modelSourceId, modelSource);
        return await response.json();
    }

    static async deleteModel(projectId, modelSourceId, modelId) {
        await del(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/contents/model-sources/" + modelSourceId + "/models/" + modelId);
    }

    static async deleteModels(projectId, modelSourceId) {
        await del(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/contents/model-sources/" + modelSourceId + "/models");
    }

    static async deleteProjectUser(projectId, userId) {
        let response = await del(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/users/" + userId);
        return await response.json();
    }

    static async patchProjectUser(projectId, userId, user) {
        let response = await patch(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/users/" + userId, user);
        return await response.json();
    }

    static async getOrganization(organizationId) {
        let response = await get(process.env.REACT_APP_API_URL + "/organizations/" + organizationId);
        return await response.json();
    }

    static async deleteOrganizationUser(organizationId, userId) {
        await del(process.env.REACT_APP_API_URL + "/organizations/" + organizationId + "/users/" + userId);
    }

    static async patchOrganizationUser(organizationId, userId, user) {
        let response = await patch(process.env.REACT_APP_API_URL + "/organizations/" + organizationId + "/users/" + userId, user);
        return await response.json();
    }

    static async postOrganizationInvitation(organizationId, invitation) {
        let response = await post(process.env.REACT_APP_API_URL + "/organizations/" + organizationId + "/users/invitations", invitation);
        return await response.json();
    }

    static async postAcceptOrganizationInvitation(invitationId) {
        await post(process.env.REACT_APP_API_URL + "/users/me/organization-invitations/" + invitationId + "/accept", null);
    }

    static async postDeclineOrganizationInvitation(invitationId) {
        await post(process.env.REACT_APP_API_URL + "/users/me/organization-invitations/" + invitationId + "/decline", null);
    }

    static async deleteExternalAccess(projectId, roomId) {
        await del(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/rooms/" + roomId + "/external-access");
    }

    static async postExternalAccess(projectId, roomId) {
        let response = await post(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/rooms/" + roomId + "/external-access", null);
        return await response.json();
    }

    static async patchRoom(projectId, roomId, room) {
        let response = await patch(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/rooms/" + roomId, room);
        return await response.json();
    }

    static async postLocalConvertedModelSource(projectId, modelSource) {
        let response = await post(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/contents/local-converted", modelSource);
        return await response.json();
    }

    static async postLocalModelSource(projectId, modelSource) {
        let response = await post(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/contents/local", modelSource);
        return await response.json();
    }
}

export default VrexApi
