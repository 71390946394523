import { get, post, put, del } from './vrexAuth';

const bcfExtensionsApiUrl = process.env.REACT_APP_BCF_EXTENSIONS_API_URL;

class BcfExtensionsApi {
    static async getLabels(projectId) {
        let response = await get(`${bcfExtensionsApiUrl}/projects/${projectId}/labels`);
        return await response.json();
    }

    static async createLabel(projectId, labelName) {
        let input = {
            label: labelName,
        };
        let response = await post(`${bcfExtensionsApiUrl}/projects/${projectId}/labels`, input);
        return await response.json();
    }

    static async updateLabel(projectId, labelId, labelName) {
        let input = {
            label: labelName,
        };
        let response = await put(
            `${bcfExtensionsApiUrl}/projects/${projectId}/labels/${labelId}`,
            input
        );
        return await response.json();
    }

    static async deleteLabel(projectId, labelId) {
        await del(`${bcfExtensionsApiUrl}/projects/${projectId}/labels/${labelId}`);
    }
}

export default BcfExtensionsApi;
