import {get_} from "./util";


export class OpenApi {
    static async getConverters() {
        let response = await get_(process.env.REACT_APP_OPEN_API_URL + "/converters");
        return await response.json()
    }

    static async getIdentityProvider(email) {
        let response = await get_(process.env.REACT_APP_OPEN_API_URL + "/identity-provider?email=" + email);
        return await response.json()
    }
}
