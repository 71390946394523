import {mustBeSuccessful} from "../common/util";

export async function get_(url, headers = null) {
    return request('GET', url, null, headers);
}

export async function post_(url, body, headers = null) {
    return await request('POST', url, body, headers);
}

export async function put_(url, body, headers = null) {
    return await request('PUT', url, body, headers);
}

export async function patch_(url, body, headers = null) {
    return await request('PATCH', url, body, headers);
}

export async function del_(url, headers = null) {
    const response = await fetch(url, {
        method: "DELETE",
        headers: headers,
    });
    return await mustBeSuccessful(response);
}

async function request(method, url, body, headers) {
    const response = await fetch(url, {
        method: method,
        headers: {
            Accept: "application/json",
            ...headers,
        },
        body: body !== null ? JSON.stringify(body) : null
    });
    return await mustBeSuccessful(response);
}
