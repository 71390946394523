import { useState, cloneElement, useEffect } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Tooltip} from "@mui/material";

export function ConfirmedAction({
                                    title,
                                    description,
                                    onOpen,
                                    onConfirmed,
                                    tooltip,
                                    shouldConfirmOnClick = () => true,
                                    ...props
                                }) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        if (shouldConfirmOnClick()) {
            setOpen(true);
        } else {
            onConfirmed();
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirmed = () => {
        setOpen(false);
        onConfirmed();
    }

    return (
        <>{tooltip ?
            <Tooltip title={tooltip}>
                <span>
                    {cloneElement(props.children, {onClick: handleClickOpen})}
                </span>
            </Tooltip>
            :
            cloneElement(props.children, {onClick: handleClickOpen})
        }
            <ConfirmDialog
                open={open}
                onOpen={onOpen}
                handleClose={handleClose}
                title={title}
                description={description}
                handleConfirmed={handleConfirmed}
            />
        </>
    );
}


function ConfirmDialog({open, onOpen, handleClose, title, description, handleConfirmed}) {
    const [loading, setLoading] = useState(false);
    const [hasCalledOnOpen, setHasCalledOnOpen] = useState(false);

    useEffect(() => {
        if (open) {
            setHasCalledOnOpen(false);
        }
    }, [open]);

    useEffect(() => {
        async function load() {
            setLoading(true);
            await onOpen();
            setLoading(false);
        }

        if (open && onOpen && !hasCalledOnOpen) {
            setHasCalledOnOpen(true);
            load();
        }
    }, [onOpen, open, hasCalledOnOpen]);

    return <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        closeAfterTransition={false}
    >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
            {loading ?
                <CircularProgress /> :
                <DialogContentText id="alert-dialog-description">
                    {description}
                </DialogContentText>
            }
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} variant={"outlined"} color="secondary" autoFocus>
                NO
            </Button>
            <Button onClick={handleConfirmed} variant={"contained"} color="primary" disabled={loading}>
                YES
            </Button>
        </DialogActions>
    </Dialog>;
}
