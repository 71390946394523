import React, {useCallback, useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import UnauthenticatedPage from "../component/UnauthenticatedPage";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoaderButton from "../component/LoaderButton";
import deviceAuthApi from "../api/deviceAuthApi";
import {OpenApi} from "../api/openApi";
import Link from "@mui/material/Link";
import {InputAdornment, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Edit, Visibility, VisibilityOff} from "@mui/icons-material";
import HTTPError, {validateEmail} from "../common/util";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useAppContext} from "../common/context";
import {logout} from "../api/vrexAuth";

function formatUserCode(userCode) {
    if (!userCode)
        return userCode;
    userCode = userCode.toUpperCase();
    userCode = userCode.match(/[A-Z0-9]/g).join("");
    if (userCode.length > 4) {
        userCode = userCode.substring(0, 4) + "-" + userCode.substring(4, 8);
    }
    return userCode;
}

const USER_CODE_LENGTH = 9;

export default function DeviceAuth() {
    const {setToast, identityProvider, user, userHasAuthenticated} = useAppContext();
    const {t} = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [userCode, setUserCode] = useState("");
    const [handledSearchParams, setHandledSearchParams] = useState(false);
    const [authRequest, setAuthRequest] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [defaultLogin, setDefaultLogin] = useState(false);
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const handleFetchingAuthRequest = useCallback(async (userCode) => {
        setIsLoading(true);
        try {
            let request = await deviceAuthApi.getRequest(userCode);
            setAuthRequest(request);
        } catch (e) {
            if (e instanceof HTTPError && e.statusCode === 404) {
                setToast({
                    message: t('deviceAuth.error'),
                    error: "request not found, if the code is correct you should restart the login process",
                    severity: "error"
                });
            } else {
                setToast({message: t('deviceAuth.error'), error: e, severity: "error"});
            }
        }
        setIsLoading(false);
    }, [setToast, t]);

    useEffect(() => {
            if (handledSearchParams) {
                return;
            }
            setHandledSearchParams(true);
            let userCode = searchParams.get("user_code");
            if (!userCode)
                return;
            let formattedUserCode = formatUserCode(userCode);
            if (formattedUserCode.length === USER_CODE_LENGTH && userCode.length === USER_CODE_LENGTH) {
                setUserCode(userCode);
                handleFetchingAuthRequest(userCode).then()
            } else {
                searchParams.delete("user_code");
                setSearchParams(searchParams);
                setUserCode("");
            }
        }, [handleFetchingAuthRequest, handledSearchParams, searchParams, setSearchParams]
    );

    useEffect(() => {
        if (user) {
            setEmail(user.email);
            if (identityProvider?.name === 'default')
                setDefaultLogin(true);
        }
    }, [user, identityProvider])

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    function handleUserCodeInput(event) {
        let userCode = formatUserCode(event.target.value);
        setUserCode(userCode);
    }

    async function handleEmailSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        let finalLoadingState = false;
        try {
            let idp = await OpenApi.getIdentityProvider(email);
            if (idp.name !== "default") {
                try {
                    let res = await deviceAuthApi.approveOAuth(userCode, `${window.location.origin}/device-auth/callback`, authRequest.nonce);
                    //TODO perhaps we should send the identity_provider to deviceAuthApi and let it fix the url?
                    window.location.replace(`${res.authUrl}&identity_provider=${idp.name}`);
                    finalLoadingState = true;
                } catch (e) {
                    if (e instanceof HTTPError && e.statusCode === 404) {
                        setToast({
                            message: t('deviceAuth.error'),
                            error: "request expired, please restart login process",
                            severity: "error"
                        });
                    } else {
                        setToast({message: t('deviceAuth.error'), error: e, severity: "error"});
                    }
                }
            } else {
                setDefaultLogin(true);
            }
        } catch (e) {
            setToast({message: t('deviceAuth.error'), error: e, severity: "error"});
        }
        setIsLoading(finalLoadingState);
    }

    async function handleLogin(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            await deviceAuthApi.approveUsernamePassword(userCode, email, password, authRequest.nonce);
            navigate(`/device-auth/callback?status=success&client_id=${authRequest.clientId}`, {replace: true});
        } catch (e) {
            let toastError = e;
            if (e instanceof HTTPError) {
                if (e.statusCode === 404) {
                    toastError = 'request expired, please restart login process';
                } else if (e.statusCode === 400) {
                    toastError = e.message;
                }
            }
            setToast({ message: t('deviceAuth.error'), error: toastError, severity: 'error', });
        }
        setIsLoading(false);
    }

    function renderUserCodeInput() {
        return (
            <>
                <TextField value={userCode}
                           label={t('deviceAuth.code')}
                           margin={"normal"}
                           autoFocus
                           required={true}
                           onChange={handleUserCodeInput}
                           sx={{input: {textAlign: "center"}, mb: 4}}
                           helperText={t('deviceAuth.helpText')}

                />
                <LoaderButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    isLoading={isLoading}
                    disabled={userCode.length !== USER_CODE_LENGTH}
                    onClick={() => handleFetchingAuthRequest(userCode)}
                >
                    {t('general.next')}
                </LoaderButton>
            </>
        )
    }

    async function handleSignup() {
        if (user) {
            await logout();
            userHasAuthenticated(false);
        }
        navigate("/signup", {state: {email: email}});
    }

    async function handleResetPassword() {
        if (user) {
            await logout();
            userHasAuthenticated(false);
        }
        navigate("/login/reset", {state: {email: email}});
    }

    function renderLogin() {
        return (
            <form onSubmit={handleLogin}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="email"
                    label={t('general.email')}
                    type='text'
                    InputProps={{
                        endAdornment:
                            (<InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setDefaultLogin(false)}
                                    edge="end"
                                >
                                    <Edit/>
                                </IconButton>
                            </InputAdornment>)
                    }}
                    disabled={true}
                    value={email}
                    id="email"
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={t('general.password')}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment:
                            (<InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>)
                    }}
                    value={password}
                    id="password"
                    autoComplete="off"
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Box display="flex"
                     justifyContent="right"
                     mb={2}>
                    <Tooltip placement="top"
                             title={
                                 // Was not able to get this to render correctly using the <Trans> component
                                 <>You can <Link
                                     color={"textSecondary"}
                                     onClick={handleSignup}
                                     variant="caption"
                                     underline="hover"
                                     sx={{cursor: "pointer"}}>
                                     create an account
                                 </Link> or <Link
                                     color={"textSecondary"}
                                     onClick={handleResetPassword}
                                     variant="caption"
                                     underline="hover"
                                     sx={{cursor: "pointer"}}>
                                     reset your password
                                 </Link> and
                                     then restart
                                     the login process</>
                             }
                             sx={{cursor: "pointer"}}>
                        <Link variant={"body2"}
                              underline={"hover"}
                              color={"textSecondary"}>{t("deviceAuth.login.help")}</Link>
                    </Tooltip>
                </Box>
                <LoaderButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    isLoading={isLoading}
                    disabled={password.length === 0}
                >
                    {t('deviceAuth.approve')}
                </LoaderButton>
            </form>
        );
    }

    function renderEmailCheck() {
        return (
            <form onSubmit={handleEmailSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={t('general.email')}
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{mb: 4}}
                />
                <LoaderButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    isLoading={isLoading}
                    disabled={!validateEmail(email)}
                >
                    {t('deviceAuth.approve')}
                </LoaderButton>
            </form>
        );

    }

    function render() {
        if (authRequest) {
            return (<>
                    {defaultLogin ? renderLogin() : renderEmailCheck()}
                    <Typography mt={2} align={"left"} variant={"body2"}
                                color={"textPrimary"}><Trans i18nKey="deviceAuth.warning">
                        If this login request was not initiated by you, do not approve it. Please contact <Link
                        href={"mailto:security@vrex.no"} underline="hover">security@vrex.no</Link>
                    </Trans></Typography>

                    <Typography mt={2} align={"left"} variant={"subtitle2"} fontWeight={"bold"}
                                color={"textSecondary"}>{t('deviceAuth.device.details')}</Typography>
                    <Typography align={"left"} variant={"caption"}
                                color={"textSecondary"}>{t('deviceAuth.code') + ": " + userCode}</Typography>
                    <Typography align={"left"} variant={"caption"}
                                color={"textSecondary"}>{t('deviceAuth.device.name') + ": " + authRequest.device.name}</Typography>
                    <Typography align={"left"} variant={"caption"}
                                color={"textSecondary"}>{t('deviceAuth.device.location') + ": " + authRequest.device.location}</Typography>
                    <Typography align={"left"} variant={"caption"}
                                color={"textSecondary"}>{t('deviceAuth.client') + ": " + deviceAuthApi.getClientName(authRequest.clientId)}</Typography>
                </>
            )
        }
        return renderUserCodeInput();
    }

    return (
        <UnauthenticatedPage>
            <Box display={"flex"} width={"100%"} flexDirection={"column"}>
                <Typography align={"center"} variant={"subtitle1"}
                            color={"textPrimary"}>{t('deviceAuth.title')}</Typography>
                <Typography align={"center"} variant={"body2"}
                            color={"textSecondary"}>{t("deviceAuth.description")}</Typography>
                {render()}
            </Box>
        </UnauthenticatedPage>
    )

}
