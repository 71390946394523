import {get_, post_} from "./util";
import {del, get} from "./vrexAuth";


const authApiUrl = process.env.REACT_APP_DEVICE_AUTH_API_URL
const authOpenApiUrl = process.env.REACT_APP_DEVICE_AUTH_OPEN_API_URL

class DeviceAuthApi {

    static async getRequest(userCode) {
        let response = await get_(`${authOpenApiUrl}/requests/${userCode}`);
        return await response.json();
    }

    static async approveUsernamePassword(userCode, username, password, nonce) {
        const msg = {
            username: username,
            password: password,
            nonce: nonce
        }

        return await post_(`${authOpenApiUrl}/requests/${userCode}/approve/username-password`, msg);
    }

    static async approveOAuth(userCode, redirectUri, nonce) {
        const msg = {
            redirectUri: redirectUri,
            nonce: nonce
        }

        let response = await post_(`${authOpenApiUrl}/requests/${userCode}/approve/oauth`, msg);
        return await response.json();
    }

    static getClientName(clientId) {
        const clients = {
            '92dcb82b-eb63-4045-8182-86bd0ba00ca1': 'Test Client',
            '08ffd755-b124-49a9-a132-85cd9120c125': 'Vrex Pro',
            '3b96ab0c-2238-4d19-91e9-5ab66564a3d9': 'Vrex Go(SA)',
            '140e481e-149a-4ad7-a381-034eaf34994b': 'Vrex Go',
        }

        return clients[clientId] ?? "Unknown Client"
    }

    static async getAuthorizations() {
        let response = await get(`${authApiUrl}/authorizations`);
        return await response.json();
    }

    static async deleteAuthorization(authorizationId) {
        await del(`${authApiUrl}/authorizations/${authorizationId}`);
    }
}

export default DeviceAuthApi
