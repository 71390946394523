import {Auth} from "aws-amplify";
import {del_, get_, patch_, post_, put_} from "./util";

export async function auth(headers = null) {
    return {
        Authorization: await getToken(),
        ...headers,
    }
}

export async function get(url, headers = null) {
    return get_(url, await auth(headers));
}

export async function del(url, headers = null) {
    return del_(url, await auth(headers));
}

export async function patch(url, body, headers = null) {
    return patch_(url, body, await auth(headers));
}

export async function post(url, body, headers = null) {
    return post_(url, body, await auth(headers));
}

export async function put(url, body, headers = null) {
    return put_(url, body, await auth(headers));
}

export async function getToken() {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
}

export async function logout() {
    let session = await Auth.currentSession();
    let refreshToken = session.getRefreshToken();
    let body = {
        "ClientId": process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
        "Token": refreshToken.getToken()
    }
    await fetch("https://cognito-idp.eu-central-1.amazonaws.com/", {
        method: "post",
        headers: {
            "content-type": "application/x-amz-json-1.1",
            "X-Amz-Target": "AWSCognitoIdentityProviderService.RevokeToken"
        },
        body: JSON.stringify(body)
    });
    await Auth.signOut();
}
