import { get } from './vrexAuth';

const bcfApiUrl = process.env.REACT_APP_BCF_API_URL;

class BcfApi {
    static async getTopics(projectId, query = null) {
        let url = `${bcfApiUrl}/projects/${projectId}/topics`;
        if (query) {
            url += '?' + query;
        }
        let response = await get(url);
        return await response.json();
    }
}

export default BcfApi;
