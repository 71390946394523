import React from "react";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import Projects from "./containers/Projects";
import Login from "./containers/Login";
import SignUp from "./containers/Signup";
import {Project} from "./containers/Project";
import Organizations from "./containers/Organizations";
import Reset from "./containers/Reset";
import Help from "./containers/Help";
import Downloads from "./containers/Downloads";
import Confirm from "./containers/Confirm";
import IntegrationError from "./containers/IntegrationError";
import {useAppContext} from "./common/context";
import LoginCallback from "./containers/LoginCallback";
import DeviceAuth from "./containers/DeviceAuth";
import DeviceAuthCallback from "./containers/DeviceAuthCallback";
import Devices from "./containers/Devices";

function RequireUnAuth({children}) {
    const {isAuthenticated} = useAppContext();
    if (isAuthenticated) {
        let redirect = sessionStorage.getItem("redirectAfterLogin");
        sessionStorage.removeItem("redirectAfterLogin");
        if (redirect) {
            return <Navigate to={redirect} replace/>
        } else {
            return <Navigate to={"/projects"} replace/>
        }
    }
    return children;
}

function DefaultRedirect() {
    const {isAuthenticated} = useAppContext();
    return isAuthenticated ? <Navigate to={'/projects'} replace/> : <Navigate to={'/login'} replace/>;
}

export function RequireAuth({children}) {
    const {pathname, search} = useLocation();
    const {isAuthenticated} = useAppContext();
    if (isAuthenticated) {
        return children;
    }
    sessionStorage.setItem("redirectAfterLogin", `${pathname}${search}`);
    return <Navigate to={`/login`} replace/>;
}

export default function AppRoutes() {
    return (
        <Routes>
            <Route path="/projects" element={
                <RequireAuth>
                    <Projects/>
                </RequireAuth>
            }/>
            <Route path="/projects/:projectId/*" element={
                <RequireAuth>
                    <Project/>
                </RequireAuth>
            }/>
            <Route path="/organizations/*" element={
                <RequireAuth>
                    <Organizations/>
                </RequireAuth>
            }/>
            <Route path="/devices" element={
                <RequireAuth>
                    <Devices/>
                </RequireAuth>
            }/>
            <Route path="/help" element={
                <RequireAuth>
                    <Help/>
                </RequireAuth>
            }/>
            <Route path="/downloads" element={
                <RequireAuth>
                    <Downloads/>
                </RequireAuth>
            }/>

            <Route path="/login" element={
                <RequireUnAuth>
                    <Login/>
                </RequireUnAuth>
            }/>
            <Route path="/login/reset" element={
                <RequireUnAuth>
                    <Reset/>
                </RequireUnAuth>
            }/>
            <Route path="/login/callback" element={
                <RequireUnAuth>
                    <LoginCallback/>
                </RequireUnAuth>
            }/>
            <Route path="/signup" element={
                <RequireUnAuth>
                    <SignUp/>
                </RequireUnAuth>
            }/>
            <Route path="/confirm" element={
                <RequireUnAuth>
                    <Confirm/>
                </RequireUnAuth>
            }/>
            <Route path="/device-auth" element={<DeviceAuth/>}/>
            <Route path="/device-auth/callback" element={<DeviceAuthCallback/>}/>
            <Route path="/integration-error" element={<IntegrationError/>}/>
            <Route path={"*"} element={<DefaultRedirect/>}/>
        </Routes>
    );
}

