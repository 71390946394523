import Box from "@mui/material/Box";
import {Tooltip} from "@mui/material";
import {BlockOutlined, HourglassEmptyOutlined} from "@mui/icons-material";
import React from "react";


export function validatePasswordField(password) {
    if (!password.visited) {
        password.valid = false;
        password.errors = [];
        return;
    }
    if (password.value.length === 0) {
        password.valid = false;
        password.errors = ["Password is required"];
        return;
    }
    let validation = validatePassword(password.value);
    password.valid = validation.valid;
    password.errors = validation.errors;
}

function validatePassword(password) {
    let errors = [];
    if (password.length < 12) {
        errors.push("Must be at least 12 characters");
        errors.push(<br key={errors.length}/>);
    }
    let re = /[a-z]/;
    if (!re.test(password)) {
        errors.push("Must contain a lowercase letter");
        errors.push(<br key={errors.length}/>);
    }
    re = /[A-Z]/;
    if (!re.test(password)) {
        errors.push("Must contain an uppercase letter");
        errors.push(<br key={errors.length}/>);
    }
    re = /\d/;
    if (!re.test(password)) {
        errors.push("Must contain a number");
        errors.push(<br key={errors.length}/>);
    }
    re = /[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/;
    if (!re.test(password)) {
        errors.push("Must contain a special character ^$*.[]{}()?\"!@#%&/\\,><':;|_~`");
        errors.push(<br key={errors.length}/>);
    }
    re = /\s/;
    if (re.test(password)) {
        errors.push("Can not contain white space characters");
        errors.push(<br key={errors.length}/>);
    }
    return {valid: errors.length === 0, errors};
}

export function validateEmailField(email) {
    if (!email.visited) {
        email.valid = false;
        email.errors = [];
        return
    }
    if (email.value.length === 0) {
        email.valid = false;
        email.errors = ["Email is required"];
        return;
    }
    let validEmail = validateEmail(email.value);
    if (validEmail) {
        email.valid = true;
        email.errors = [];
        return;
    }
    email.valid = false;
    email.errors = ["Invalid email"];
}

export function requiredField(field, errorText) {
    if (!field.visited) {
        field.valid = false;
        field.errors = [];
        return
    }
    if (field.value.length === 0) {
        field.valid = false
        field.errors = [errorText]
        return;
    }
    field.valid = true;
    field.errors = [];
}

export async function sleep(n) {
    return new Promise(resolve => setTimeout(resolve, n));
}

export async function uploadFile(url, file, id, progressCallback) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {
                    progressCallback(id, 100);
                    resolve(true);
                }
                reject("error");
            }
        };

        xhr.upload.onprogress = (evt) => {
            if (evt.lengthComputable) {
                let percentComplete = parseInt(
                    (evt.loaded / evt.total) * 100,
                    10
                );
                percentComplete = Math.min(Math.max(percentComplete, 0), 99);
                progressCallback(id, percentComplete);
            }
        };

        xhr.onerror = function () {
            const error = new Error('Upload failed. Please try again, or contact support.');
            reject(error);
        };

        xhr.open('PUT', url, true);
        xhr.send(file);
    });
}

export async function mustBeSuccessful(response) {
    if (response.status < 200 || response.status >= 300) {
        let errorMessage = '';
        try {
            const body = await response.text();
            const json = JSON.parse(body);
            if (json.message) {
                errorMessage = json.message;
            }
        } catch {
        }
        throw new HTTPError(response.status, response.statusText, errorMessage);
    }
    return response
}

class HTTPError extends Error {
    constructor(code, name, message) {
        super(message);
        this.name = name;
        this.statusCode = code;
    }

    toString() {
        return this.message || `${this.statusCode} ${this.name}`;
    }
}

export default HTTPError;

export function errorString(error) {
    let message = error.toString();
    if (!(error instanceof Error) && error.message) {
        message = error.message;
    }
    return message;
}

export function proxyEncode(url) {
    if (process.env.REACT_APP_LOCAL_API === "true") {
        return url;
    }
    return url.replaceAll("$", "%2524").replaceAll(" ", "%2520");
}

export function validateEmail(email) {
    // https://www.w3.org/TR/2012/WD-html-markup-20120329/input.email.html
    const re = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return re.test(email);
}

export const Capacity = Object.freeze({UNLIMITED: 2147483647})

export const Roles = Object.freeze({
    ADMIN: {name: "ADMIN", accessLevel: 400, title: "Admin"},
    CREATOR: {name: "CREATOR", accessLevel: 300, title: "Creator"},
    COORDINATOR: {name: "COORDINATOR", accessLevel: 200, title: "Coordinator"},
    USER: {name: "USER", accessLevel: 100, title: "User"},
})

export function getRole(accessLevel) {
    let match = Roles.USER;
    Object.values(Roles).forEach((role) => {
        if (role.accessLevel === accessLevel) {
            match = role;
        }
    })
    return match;
}

export function getStatusIcon(status, t) {
    switch (status) {
        case Status.PENDING.name:
            return <Box display={"flex"}><Tooltip title={t('general.users.status.pending')}><HourglassEmptyOutlined
                style={{fontSize: "1.2rem"}}/></Tooltip></Box>
        case Status.DECLINED.name:
            return <Box display={"flex"}><Tooltip title={t('general.users.status.declined')}><BlockOutlined
                style={{fontSize: "1.2rem"}}/></Tooltip></Box>
        default:
            return ""
    }
}

export const Status = Object.freeze({
    PENDING: {name: "PENDING", title: "Pending"},
    CONFIRMED: {name: "CONFIRMED", title: "Confirmed"},
    DECLINED: {name: "DECLINED", title: "Declined"}
})

export function getStatus(status) {
    let match = Status.PENDING;
    Object.values(Status).forEach((s) => {
        if (s.name === status) {
            match = s;
        }
    })
    return match;
}


