import React, {useState} from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {Auth} from "aws-amplify";
import LoaderButton from "../component/LoaderButton";
import {useAppContext} from "../common/context";
import UnauthenticatedPage from "../component/UnauthenticatedPage";
import Link from "@mui/material/Link";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {requiredField, validateEmailField, validatePasswordField} from "../common/util";
import {InputAdornment} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import Box from "@mui/material/Box";


export default function Reset() {
    const {userHasAuthenticated, setToast} = useAppContext();
    const [isLoading, setIsLoading] = useState(false)
    const [codeSent, setCodeSent] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    let email = location.state ? location.state.email : "";
    const [fields, setFields] = useState({
        email: {value: email, visited: false, valid: !!email, errors: []},
        password: {value: "", visited: false, valid: false, errors: []},
        confirmationCode: {value: "", visited: false, valid: false, errors: []},
    });
    const {t} = useTranslation();
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    function handleFieldChange(event) {
        fields[event.target.name].value = event.target.value;
        validateFields();
        setFields({
            ...fields,
        });
    }

    function handleBlur(event) {
        fields[event.target.name].visited = true;
        validateFields();
        setFields({
            ...fields,
        });
    }

    function validateFields() {
        if (codeSent) {
            requiredField(fields.confirmationCode, "Confirmation code is required");
            validatePasswordField(fields.password);
        } else {
            validateEmailField(fields.email);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            await Auth.forgotPassword(fields.email.value);
            setIsLoading(false);
            setCodeSent(true);
        } catch (e) {
            setToast({message: t('reset.error'), error: e, severity: "error"})
            setIsLoading(false)
        }
    }

    async function handleConfirmationSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            await Auth.forgotPasswordSubmit(fields.email.value, fields.confirmationCode.value, fields.password.value);
            await Auth.signIn(fields.email.value, fields.password.value);
            userHasAuthenticated(true);
        } catch (e) {
            setToast({message: t('reset.error'), error: e, severity: "error"});
            setIsLoading(false);
        }
    }

    function validateConfirmationForm() {
        return fields.email.valid && fields.confirmationCode.valid && fields.password.valid;
    }

    function validateForgotPasswordForm() {
        return fields.email.valid;
    }

    function renderConfirmationForm() {
        return (
            <form noValidate onSubmit={handleConfirmationSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={t('general.email')}
                    name="email"
                    autoComplete="email"
                    value={fields.email.value}
                    disabled={true}
                />
                <TextField
                    name="confirmationCode"
                    variant="outlined"
                    required
                    fullWidth
                    id="confirmationCode"
                    label={t('reset.confirmation.code')}
                    autoFocus
                    value={fields.confirmationCode.value}
                    error={fields.confirmationCode.errors.length > 0}
                    helperText={fields.confirmationCode.errors.length>0?fields.confirmationCode.errors:t('reset.confirmation.description')}
                    onChange={handleFieldChange}
                    onBlur={handleBlur}
                    sx={{mt: 2}}
                />
                <TextField
                    name="password"
                    autoComplete="off"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label={t('general.password')}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment:
                            (<InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>)
                    }}
                    value={fields.password.value}
                    error={fields.password.errors.length > 0}
                    helperText={fields.password.errors}
                    onChange={handleFieldChange}
                    onBlur={handleBlur}
                    sx={{mb: 4}}
                />

                <LoaderButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    isLoading={isLoading}
                    disabled={!validateConfirmationForm()}
                >
                    {t('reset.confirmation.submit')}
                </LoaderButton>
            </form>);

    }

    function renderForgotPasswordForm() {
        return (
            <form noValidate onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={t('general.email')}
                    name="email"
                    autoComplete="email"
                    value={fields.email.value}
                    error={fields.email.errors.length > 0}
                    helperText={fields.email.errors}
                    onChange={handleFieldChange}
                    onBlur={handleBlur}
                    sx={{mb: 4}}
                />
                <LoaderButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    isLoading={isLoading}
                    disabled={!validateForgotPasswordForm()}
                >
                    {t('reset.submit')}
                </LoaderButton>
            </form>);
    }

    return (
        <UnauthenticatedPage>
            <Typography color={"textPrimary"} variant={"subtitle1"}>{t("reset.title")}</Typography>
            {codeSent ? renderConfirmationForm() : renderForgotPasswordForm()}
            <Box mt={1} width={"100%"}>
                <Link
                    color={"primary"}
                    to={"/login"}
                    variant="body2"
                    style={{cursor: "pointer"}}
                    underline="hover"
                    onClick={() => navigate("/login", {state: {email: fields.email.value}})}>
                    {t('reset.remembered')}
                </Link>
            </Box>
        </UnauthenticatedPage>
    );
}
